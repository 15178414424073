import gsap from "gsap"
import LogoImage from "images//WordmarkLogo.svg"
import { DesktopTabletOnly, MobileOnly } from "library/breakpointUtils"
import { eases } from "library/eases"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import UniversalLink from "library/Loader/UniversalLink"
import useAnimation from "library/useAnimation"
import { useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import links from "utils/links"

import LinkButton from "./buttons/LinkButton"
import PrimaryCTA from "./buttons/PrimaryCTA"
import MobileDropdown from "./MobileDropdown"
import { preloaderDuration } from "./Preloader"

export default function Header() {
  const logo = useRef<HTMLImageElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const mobileLogo = useRef<HTMLImageElement>(null)
  const mobileWrapper = useRef<HTMLDivElement>(null)

  /**
   * sync logo with the preloader
   */
  useAnimation(() => {
    gsap.set([logo.current, mobileLogo.current], { opacity: 0 })
    gsap.to([logo.current, mobileLogo.current], {
      opacity: 1,
      delay: preloaderDuration,
    })
  }, [])

  /**
   * animate in the buttons
   */
  useEffect(() => {
    if (!wrapper.current) return
    if (!mobileWrapper.current) return

    const elementsToAnimate = [
      ...[...wrapper.current.children].slice(1),
      ...[...mobileWrapper.current.children].slice(1),
    ]

    elementsToAnimate.forEach(element => {
      element.classList.add("before-slide-in")
    })

    const onLoad = () => {
      elementsToAnimate.forEach((element, index) => {
        setTimeout(
          () => {
            element.classList.add("slide-in")
            element.classList.remove("before-slide-in")
          },
          index * 100 + 500,
        )
      })
    }

    registerLoaderCallback({
      callback: onLoad,
      duration: 1,
    })

    return () => {
      unregisterLoaderCallback(onLoad)
    }
  }, [])

  return (
    <>
      <DesktopTabletOnly>
        <Wrapper ref={wrapper}>
          <UniversalLink to="/" ariaLabel="Response Home">
            <Logo src={LogoImage} alt="Response Logo" ref={logo} />
          </UniversalLink>
          <LinkButton to={links.pricing}>Pricing</LinkButton>
          <LinkButton to={links.about}>About Us</LinkButton>
          <LinkButton to={links.careers}>Careers</LinkButton>
          <LinkButton to={links.signIn}>Sign In</LinkButton>
          <div>
            <PrimaryCTA type="calendly">Get Started</PrimaryCTA>
          </div>
        </Wrapper>
      </DesktopTabletOnly>
      <MobileOnly>
        <Wrapper ref={mobileWrapper}>
          <UniversalLink to="/" ariaLabel="Response Home">
            <Logo src={LogoImage} alt="Response Logo" ref={mobileLogo} />
          </UniversalLink>
          <div />
          <MobileDropdown />
        </Wrapper>
      </MobileOnly>
    </>
  )
}

/**
 * animating this with GSAP can cause pop-in on some android devices,
 * where the children will not render as they animate in
 */
const slideIn = keyframes`
  0% { translate: 0 -20vw; opacity: 0 }
  0.1% { opacity: 1 }
  100% { translate: 0 0 }
`

const Wrapper = styled.div`
  .before-slide-in {
    opacity: 0;
  }

  .slide-in {
    animation: ${slideIn} 1s ${eases.cubic.out};
  }

  ${fresponsive(css`
    display: flex;
    gap: 80px;
    align-items: center;
    padding: 24px 0;
    width: 1360px;
    margin: 0 auto;
  `)}

  ${ftablet(css`
    width: 944px;

    & > *:last-child {
      margin-left: auto;
    }
  `)}

  ${fmobile(css`
    width: 335px;
    gap: 0;
  `)}

  & > *:first-child {
    margin-right: auto;
  }
`

const Logo = styled.img`
  ${fresponsive(css`
    width: 128px;
    max-width: none;
  `)}
`
