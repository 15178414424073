const links = {
  // pages on the site
  home: "/",
  about: "/about",
  pricing: "/pricing",
  faq: "/pricing#faq",

  // external links
  careers: "https://jobs.ashbyhq.com/response",
  contact: "mailto:contact@tryresponse.com",
  signIn: "https://app.tryresponse.com/login/",

  // legal
  privacy: "/privacy",
  terms: "/terms",

  // socials
  twitter: "https://twitter.com/tryresponse",
  facebook: "https://www.facebook.com/tryresponse/",
  instagram: "https://instagram.com/tryresponse",
  linkedin: "https://www.linkedin.com/company/responseinc",
}

export default links
