import type { CalendlyProps } from "components/CalendlyButton"
import CalendlyButton from "components/CalendlyButton"
import { ReactComponent as ArrowSVG } from "images/arrowUpRight.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const ease = "cubic-bezier(0.76, 0, 0.09, 1)"

type Props = CalendlyProps & {
  light?: boolean
}

export default function PrimaryCTA({
  children,
  light = false,
  ...props
}: Props) {
  return (
    <Wrapper {...props} $light={light}>
      <Marquee>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
        <Slide $light={light}>
          {children}
          <Arrow />
        </Slide>
      </Marquee>
      <Text>{children}</Text>
    </Wrapper>
  )
}

const marqueeAnimation = keyframes`
  from {
    translate: -50% 0;
  }
  to {
    translate: -150% 0;
  }
`

const Marquee = styled.div`
  position: absolute;
  display: flex;
  translate: 0 500%;
  transition: translate 0.6s ${ease};
`

const Text = styled.div`
  transition: translate 0.6s ${ease};
  width: 100%;
  text-align: center;
`

const Wrapper = styled(CalendlyButton)<{
  $light: boolean
}>`
  ${fresponsive(css`
    padding: 30px 45px;
    border-radius: 20px;
  `)}

  ${textStyles.button1bold};
  position: relative;
  display: block;
  overflow: clip;
  color: ${({ $light }) => ($light ? colors.blue2 : colors.pureWhite)};
  background: ${({ $light }) => ($light ? colors.pureWhite : colors.blue2)};
  border: 1px solid
    ${({ $light }) => ($light ? colors.pureWhite : colors.blue2)};
  transition:
    border 0.6s ${ease},
    background 0.6s ${ease};

  &:hover {
    border: 1px solid
      ${({ $light }) => ($light ? colors.pureWhite : colors.blue3)};
    background: ${colors.transBlue1};

    ${Text} {
      translate: 0 -500%;
    }

    ${Marquee} {
      translate: 0 0;
    }
  }

  ${fmobile(css`
    width: 100%;
    display: grid;
    place-items: center;
  `)}
`

const Slide = styled.div<{
  $light: boolean
}>`
  ${fresponsive(css`
    gap: 9px;
    padding-left: 9px;
  `)}

  display: flex;
  white-space: nowrap;
  align-items: center;
  animation: ${marqueeAnimation} 3s linear infinite;
  color: ${({ $light }) => ($light ? colors.blue2 : colors.blue3)};

  path {
    stroke: ${({ $light }) => ($light ? colors.blue2 : colors.blue3)};
  }
`

const Arrow = styled(ArrowSVG)`
  ${fresponsive(css`
    width: 9px;
    height: 9px;
  `)}
`
