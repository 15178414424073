module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.12_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Response","short_name":"Response","start_url":"/","background_color":"#1F1F25","theme_color":"#1F1F25","display":"minimal-ui","icon":"./src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9453d45c6332f49aafd505b3746eba5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.12.0_babel-plugin-styled-components@2.1.4_gatsby@5.12.12_re_4pdigbcjoq2aa3vtsjkjxvlmx4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.12_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
