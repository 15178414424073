import * as Dialog from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { useLocation } from "@reach/router"
import { useThrottle } from "ahooks"
import { ReactComponent as ArrowSVG } from "images/arrowUpRight.svg"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { pathnameMatches } from "library/functions"
import UniversalLink from "library/Loader/UniversalLink"
import { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

export default function MobileDropdown() {
  const pathname = useLocation().pathname
  const [rawOpen, setOpen] = useState(false)

  // prevent bad animations on fast clickin
  const open = useThrottle(rawOpen, { wait: 500 })

  // close when navigating
  useEffect(() => setOpen(false), [pathname])

  // smooth scroll to top when open
  useEffect(() => {
    if (open) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [open])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Trigger $open={open} aria-label={open ? "Close Menu" : "Open Menu"}>
        <Line />
        <Line />
        <Line />
      </Trigger>

      <Dialog.Portal>
        <Overlay />
        <Content>
          <VisuallyHidden.Root>
            <Dialog.Title>Menu</Dialog.Title>
            <Dialog.Close>Close Menu</Dialog.Close>
          </VisuallyHidden.Root>
          <Link to="/" $disabled={pathnameMatches("/", pathname)}>
            Home
            <Arrow />
          </Link>
          <Link
            to={links.pricing}
            $disabled={pathnameMatches(links.pricing, pathname)}
          >
            Pricing
            <Arrow />
          </Link>
          <Link
            to={links.about}
            $disabled={pathnameMatches(links.about, pathname)}
          >
            About
            <Arrow />
          </Link>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const Trigger = styled(Dialog.Trigger)<{
  $open: boolean
}>`
  ${fresponsive(css`
    padding: 16px 13px;
    background-color: ${colors.blue2};
    border-radius: 10px;
    display: grid;
    gap: 4px;
  `)}

  ${({ $open }) =>
    $open &&
    fresponsive(css`
      ${Line} {
        &:nth-child(1) {
          rotate: -30deg;
          translate: 0 5px;
        }

        &:nth-child(2) {
          rotate: 30deg;
        }

        &:nth-child(3) {
          rotate: -30deg;
          translate: 0 -5px;
        }
      }
    `)}
`

const Line = styled.div`
  ${fresponsive(css`
    height: 1px;
    width: 40px;
    background-color: ${colors.pureWhite};
    transition: 0.2s ${eases.cubic.out};
    transition-property: rotate, translate, scale;
  `)}
`

const slideIn = keyframes`
  /* we need to start on screen to prevent any scroll-to-element from happening */
  0% {
    opacity: 0;
    translate: 0 0;
  }
  1% {
    opacity:  0;
    translate: 0 -100vh;
  }
  2% {
    opacity:  1;
    translate: 0 -100vh;
  }
  100% {
    opacity:  1;
    translate: 0 0
  }
`

const slideOut = keyframes`
  from {
    translate: 0 0
  }
  to {
    translate: 0 -100vh
  }
`

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`

const fadeOut = keyframes`
  from { opacity: 1 }
  to { opacity: 0 }
`

const Overlay = styled(Dialog.Overlay)`
  ${fresponsive(css`
    position: fixed;
    z-index: 1;
    inset: 80px 0 0;
    background: rgb(245 245 250 / 75%);
    backdrop-filter: blur(6.5px);
  `)}

  &[data-state="open"] {
    animation: ${fadeIn} 0.5s ${eases.cubic.out};
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.5s ${eases.cubic.in};
  }
`

const Content = styled(Dialog.Content)`
  ${fresponsive(css`
    display: grid;
    width: 336px;
    padding: 17px 18px;
    border-radius: 10px;
    border: 1px solid ${colors.blue3};
    background: ${colors.transBlue1};
    backdrop-filter: blur(6.5px);
    position: fixed;
    top: 93px;
    left: 20px;
    z-index: 2;
  `)};

  &[data-state="open"] {
    animation: ${slideIn} 0.5s ${eases.cubic.out};
  }

  &[data-state="closed"] {
    animation: ${slideOut} 0.5s ${eases.cubic.in};
  }
`

const Link = styled(UniversalLink)<{
  $disabled: boolean
}>`
  ${textStyles.h4};
  ${fresponsive(css`
    padding: 25px 0;
    color: ${colors.blue2};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `)}

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey1};
  }

  path {
    stroke: ${colors.blue2};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${colors.grey2};

      path {
        stroke: ${colors.grey2};
      }
    `}
`

const Arrow = styled(ArrowSVG)`
  ${fresponsive(css`
    width: 25px;
    height: 25px;
  `)}
`
