const colors = {
  charcoal: "#1F1F25",
  pureWhite: "white",
  grey1: "#E0E0EB",
  grey2: "#9797A6",
  grey3: "#606073",
  grey4: "#2F2F42",
  blue1: "#030259",
  blue2: "#1916B0",
  blue3: "#2F64ED",
  blue4: "#7CB1FF",
  transBlue1: "#EAF0FDBF",
  transBlue2: "#EAF0FD66",
  orange1: "#DA4E00",
  orange2: "#FA640F",
  orange3: "#FF8743",
  transOrange1: "#FF87431F",
  green1: "#06603A",
  green2: "#17A067",
  green3: "#51B896",
  transGreen1: "#2EB3871F",
  alpine: "#F5F5FA",
} as const

export default colors
