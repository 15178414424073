import Footer from "components/Footer"
import Header from "components/Header"
import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import { useBackButton } from "library/Loader/TransitionUtils"
import { useTrackPageReady } from "library/pageReady"
import Scroll from "library/Scroll"
import useCSSHeightVariables from "library/useCssHeightVariables"
import { useEffect, useRef } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"

import Preloader from "./Preloader"

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()

  const main = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const isScrolled = window.scrollY > window.innerHeight
    if (!isScrolled) ScrollSmoother.get()?.scrollTo(0, false)

    const onLoad = () => {
      if (isScrolled) return

      gsap.fromTo(
        main.current,
        {
          y: "100vh",
        },
        {
          y: 0,
          duration: 1,
          ease: "power2.inOut",
          onComplete: () => {
            // reset so as not to interfere with pins
            gsap.set(main.current, { clearProps: "all" })
          },
        },
      )
    }

    registerLoaderCallback({
      callback: onLoad,
      // give everything a little extra time to settle before refreshing
      duration: isScrolled ? 0 : 1.4,
    })

    return () => {
      unregisterLoaderCallback(onLoad)
    }
  }, [])

  return (
    <Clipper>
      <Preloader />
      <GlobalStyle />
      <Scroll>
        <Header />
        <Main ref={main}>
          <Line />
          {children}
          <Footer />
        </Main>
      </Scroll>
    </Clipper>
  )
}

const Clipper = styled.div`
  /* this can't be on the body or html, so we get a special wrapper for it */
  max-width: 100%;
  overflow-x: hidden;
`

const Main = styled.main`
  max-width: 1440px;
  margin: 0 auto;
`

const Line = styled.div`
  height: 1px;
  background: ${colors.grey1};

  ${fresponsive(css`
    width: 1360px;
    margin: 0 auto;
  `)}

  ${ftablet(css`
    width: 944px;
  `)}

${fmobile(css`
    width: 335px;
  `)}
`

const globalCss = css`
  /* default text styles */
  html {
    color: ${colors.charcoal};
    background: ${colors.alpine};
  }

  * {
    /* need this so that fonts match figma */
    text-rendering: geometricprecision;
  }

  /* fixes for visbug */
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid #00f8;
  }
`

const GlobalStyle = createGlobalStyle`${globalCss}`
