import { ReactComponent as ArrowSVG } from "images/arrowUpRight.svg"
import { fresponsive } from "library/fullyResponsive"
import type { UniversalLinkProps } from "library/Loader/UniversalLink"
import UniversalLink from "library/Loader/UniversalLink"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

type Props = {
  small?: boolean
  showArrow?: boolean
} & UniversalLinkProps

export default function LinkButton({
  children,
  small = false,
  showArrow = false,
  ...props
}: Props) {
  return (
    <Wrapper {...props} $small={small} $showArrow={showArrow}>
      <Arrow $small={small} $showArrow={showArrow} />
      <Text $showArrow={showArrow}>{children}</Text>
    </Wrapper>
  )
}

const Arrow = styled(ArrowSVG)<{
  $small: boolean
  $showArrow: boolean
}>`
  transition: 0.5s cubic-bezier(0.49, 0, 0.22, 1);
  transition-property: rotate, translate;
  rotate: ${({ $showArrow: showArrow }) => (showArrow ? "45deg" : "10deg")};

  ${({ $small, $showArrow }) =>
    fresponsive(css`
      translate: ${$showArrow ? "0 0" : "-150% 3px"};
      width: ${$small ? "8px" : "9px"};
      height: ${$small ? "8px" : "9px"};
      margin-bottom: 2px;
    `)}

  path {
    stroke: ${colors.blue2};
  }
`

const Text = styled.div<{
  $showArrow: boolean
}>`
  ${({ $showArrow }) =>
    fresponsive(css`
      transition: 0.5s cubic-bezier(0.49, 0, 0.22, 1);
      transition-property: translate;
      translate: ${$showArrow ? "0 0" : "-16px 0"};
    `)}
`

const Wrapper = styled(UniversalLink)<{ $small: boolean; $showArrow: boolean }>`
  --rotation: ${({ $showArrow: showArrow }) => (showArrow ? "0deg" : "45deg")};
  ${({ $small }) =>
    $small ? textStyles.button2medium : textStyles.button1medium}

  ${({ $showArrow }) =>
    fresponsive(css`
      display: flex;
      align-items: center;
      gap: 6px;
      color: ${colors.blue2};
      overflow: clip;
      padding: 2px;
      margin: -2px ${$showArrow ? "-2px" : "-17px"} -2px -2px;
    `)}

  &:hover {
    ${Arrow} {
      translate: 0 0;
      rotate: var(--rotation);
    }
    ${Text} {
      translate: 0 0;
    }
  }
`
