import gsap from "gsap"
import { ReactComponent as BarcodeSVG } from "images/Barcode.svg"
import MarkIMG from "images/WordmarkLogo.svg"
import AutoAnimate from "library/AutoAnimate"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import loader from "library/Loader"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export const preloaderDuration = 1.5

export default function Preloader() {
  const logo = useRef<HTMLImageElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const barcode = useRef<HTMLDivElement>(null)

  // we will attempt to fade out the loader twice, and always want to use the second attempt
  const firstFade = useRef(true)
  const fadeOutLogo = () => {
    if (firstFade.current) {
      firstFade.current = false
      return
    }
    gsap.to(logo.current, {
      opacity: 0,
      ease: "power1.in",
    })
  }

  /**
   * animate the logo slide
   * we always want to do this right away, so we don't need to wait for the loader
   */
  useAnimation(
    () => {
      const timeline = gsap
        .timeline({
          paused: true,
        })

        .to(
          logo.current,
          { left: 0, x: 0, xPercent: -25, ease: "power4.in" },
          0,
        )
        .to(logo.current, { y: 0, ease: "power4.out" }, 0)
        .to(logo.current, { scale: 0.5, ease: "power1.inOut" }, 0)

      gsap.to(timeline, {
        progress: 1,
        duration: preloaderDuration,
        ease: "power4.inOut",
      })
      gsap.to(barcode.current, {
        opacity: 1,
        ease: "power1.out",
        delay: preloaderDuration,
      })
      gsap.delayedCall(preloaderDuration, fadeOutLogo)
    },
    [],
    {
      kill: true,
    },
  )

  useEffect(() => {
    const onLoad = () => {
      gsap.to(wrapper.current, { backgroundColor: "transparent" })
      gsap.to(barcode.current, {
        y: "-100vh",
        duration: 1,
        ease: "power2.inOut",
      })
      fadeOutLogo()
    }

    registerLoaderCallback({
      callback: onLoad,
      duration: 0,
    })

    return () => {
      unregisterLoaderCallback(onLoad)
    }
  }, [])

  /**
   * track loader progress
   */
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    loader.addEventListener("progressUpdated", setProgress)
    return () => {
      loader.removeEventListener("progressUpdated", setProgress)
    }
  }, [])

  return (
    <Wrapper ref={wrapper}>
      <Header>
        <Logo src={MarkIMG} alt="Response Logo" ref={logo} loading="eager" />
      </Header>
      <Loader ref={barcode}>
        <LightBarcode />
        <DarkBarcode
          style={{
            clipPath: `polygon(0 0, ${progress}% 0, ${progress}% 100%, 0 100%)`,
          }}
        />
        <Text>
          <div>Loading</div>
          <AutoAnimate duration={0.2}>
            {Math.round(progress).toString().padStart(2, "0")}
          </AutoAnimate>
          %
        </Text>
      </Loader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${fresponsive(css`
    pointer-events: none;
    background-color: ${colors.alpine};
    position: fixed;
    inset: 0;
    z-index: 999;
    display: grid;
    grid-template-rows: auto 1fr 120px;
    place-items: center;
  `)}
`

const Header = styled.div`
  ${fresponsive(css`
    width: 1360px;
    margin: 24px auto;
    height: 74px;
    display: flex;
    align-items: center;
  `)}
  ${ftablet(css`
    width: 944px;
  `)}
  ${fmobile(css`
    width: 335px;
    height: 43px;
    margin: 22px auto;
  `)}
  perspective: 200px;
`

const Logo = styled.img`
  ${fresponsive(css`
    width: 256px;
    max-width: none;
    max-height: none;
    position: relative;
    left: 50%;
    transform: translate(-50%, -35px) translateY(-50%) translateY(50vh);
  `)}
`

const Loader = styled.div`
  width: min-content;
  margin: 0 auto;
  opacity: 0;
`

const LightBarcode = styled(BarcodeSVG)`
  ${fresponsive(css`
    height: 43px;
    margin-bottom: -43px;
    display: block;

    path {
      fill: ${colors.grey1};
    }
  `)}
`

const DarkBarcode = styled(LightBarcode)`
  margin-bottom: 0;
  transition: clip-path 0.2s ease-out;

  path {
    fill: ${colors.blue2};
  }
`

const Text = styled.div`
  ${textStyles.kicker1};

  ${fresponsive(css`
    display: grid;
    grid-template-columns: 1fr auto auto;
    margin-top: 10px;
  `)}
`
